<template>
  <div
    v-if="showTopHeaderSign"
    class="py-3 d-flex align-center gap-4 justify-center">
    <a :href="linkToLanding">
      <v-img
        aspect-ratio="4"
        width="200"
        src="@/assets/icons/pwrd-by-sourcery-black.svg" />
    </a>
    <v-btn
      :to="{ name: 'sign-up' }"
      class="white--text"
      color="blue"
      elevation="0">
      Try today
    </v-btn>
  </div>
</template>
<script>
export default {
  name: 'TopHeaderSignUp',
  computed: {
    linkToLanding() {
      return process.env.VUE_APP_LANDING_URL || 'https://gosourcery.com';
    },
    showTopHeaderSign() {
      return ['shared-collection', 'shared-workspace', 'project-library'].includes(this.$route.name);
    },
  },
};
</script>
